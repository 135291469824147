<template>
  <div class="row">
    <OutstateOrganizationForm :header-text="'Create an Educational Organization'"
                              :editing-existing="false" :submit-text="'Create Organization'"/>
  </div>
</template>

<script>
import {
  outstateOrganizationComposable
} from "@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationComposable";
import {onBeforeMount, ref} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import OutstateOrganizationForm
  from "@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationForm";

export default {
  name: "OutstateOrganizationCreate",
  components: {OutstateOrganizationForm},
  setup() {

  }
}
</script>

<style scoped>

</style>